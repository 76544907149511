	/*
  	Flaticon icon font: Flaticon
  	Creation date: 03/09/2020 06:32
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
  font-family: Flaticon;
font-style: normal;
}

.flaticon-heart-1:before { content: "\f100"; }
.flaticon-like:before { content: "\f101"; }
.flaticon-heart:before { content: "\f102"; }
.flaticon-church:before { content: "\f103"; }
.flaticon-church-1:before { content: "\f104"; }
.flaticon-church-2:before { content: "\f105"; }
.flaticon-church-3:before { content: "\f106"; }
.flaticon-church-4:before { content: "\f107"; }
.flaticon-cross:before { content: "\f108"; }
.flaticon-church-5:before { content: "\f109"; }
.flaticon-cross-1:before { content: "\f10a"; }
.flaticon-shopping-cart:before { content: "\f10b"; }
.flaticon-pray:before { content: "\f10c"; }
.flaticon-pray-1:before { content: "\f10d"; }
.flaticon-hand:before { content: "\f10e"; }
.flaticon-money-bag:before { content: "\f10f"; }
