button:hover,
button:focus,
:focus {
    outline: none!important;
    text-decoration: none!important;
}
.cart-open + .sigma_aside-overlay.sigma_cart-sidebar-overlay {
    opacity: 1;
    visibility: visible;
}
.cart-open.sigma_cart-sidebar-wrapper.sigma_aside {
    right: 0;
}
.mobile-open.mobile-menu {
    display: block;
}
.banner-bottom>img{
    height: 360px;
    object-fit: cover;
}
.gallery a img{
    height: 250px;
    object-fit: cover;
    width: 100%;
    object-position: center;
}
.sigma_product-additional-info .comment-body .sigma_rating span{
    margin-bottom: 0;
    position: relative;
}
.sigma_round-button.active {
    color: #ffffff;
    background: #ffa80a;
    border-radius: 50%;
}
.sigma_round-button.active .sigma_round-button-circle {
    stroke-dashoffset: 700;
    stroke-dasharray: 700;
}
.slick-slider .slick-track, .slick-slider .slick-list{
    display: flex;
}
.slick-slider.slick-vertical .slick-track, .slick-slider.slick-vertical .slick-list{
    display: block;
}
.CircularProgressbar{
    max-width: 140px;
}

.sigma_sermon-box .sigma_sermon-wrap .card .card-header::after,
.sigma_sermon-box .sigma_sermon-wrap .card .card-header::before{
    display: none;
}
.sigma_sermon-box .sigma_sermon-wrap .card .card-header .btn-link{
    width: 100%;
}
.sigma_sermon-box .sigma_sermon-wrap .card .card-header .btn-link::before{
    opacity: 1;
    transform: rotate(90deg);
    left: auto;
}
.sigma_sermon-box .sigma_sermon-wrap .card .collapseparent.show+.card-header .btn-link::before {
    opacity: 0;
    transform: rotate(0deg);
}
.card .card-header{
    display: flex;
    flex-basis: 100%;
    order: 1;
    border-radius: 0;
}
.card .collapseparent{
    order: 2;
}
.banner-3.sigma_banner .slick-slide{
    display: block;
}

.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single,
.irs--flat .irs-bar,
.irs--flat .irs-handle>i:first-child {
    background-color: #1f1f1f!important;
}

.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
    border-top-color: #1f1f1f!important;
}

.irs--flat .irs-handle.state_hover>i:first-child,
.irs--flat .irs-handle:hover>i:first-child {
    background-color: #1f1f1f!important;
}
.alert.d-block{
    display: block!important;
}